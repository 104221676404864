import { speedSlow } from 'consts/transitions';
import attachEnterAsClick from 'utils/attachEnterAsClick';
import $ from 'jquery';

const sgAccordionInit = () => {
    const $component = $('.sg-accordion');
    const $heading = $component.find('.accordion-heading');

    // Slide open the accordion content
    $heading.on('click', function () {
        const $el = $(this);

        if ($el.hasClass('disabled')) {
            return;
        }

        $el.addClass('disabled');

        if ($el.parent().hasClass('open')) {
            $el.parent().removeClass('open');
            $el.next().slideUp(speedSlow, () => {
                $el.removeClass('disabled');
            });
        } else {
            $el.parent().addClass('open');
            $el.next().slideDown(speedSlow, () => {
                $el.removeClass('disabled');
            });
        }
    });
    attachEnterAsClick($heading);
};

$(() => {
    sgAccordionInit();
});
