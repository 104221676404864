// This function will attach the keypress of "enter" to trigger the element's click event
// This function is only needed on 'non-native' clickable elements like div / span ....
// Things like <a> <input> <button> will not this function
import $ from 'jquery';

export default function attachEnterAsClick($element) {
    if (!$element.length) { return; }

    $element.each(function () {
        $(this).keypress(function (e) {
            // Enter key pressed
            if (e.which === 13) {
                // Trigger click event on the same element
                $(this).click();
            }
        });
    });
}

// This is a function decorator that is meant to be used to decorate normal onclick handling function to have its function extended
// to allow enter press to also trigger the same onclick handling function.
// Once decorated, the same function can be used as both onclick handler and onkeypress handler
//
// NOTE: the function being decorated must pass in the element event as the first arg, without it then no decoration will take place
export function attachEnterAsClickDecorator(fn) {
    return function (...args) {
        // No args passed, no need to decorate, simply call the function
        if (!args || !args.length) {
            fn(...args);
        }

        // Get the first arg, and check if its a key event
        const evt = args[0];
        if (!evt.which) {
            // Not a key event, then just call the function with no decoration
            fn(...args);
        } else if (evt.which === 13) {
            // We have a key event, in that case we only call the function if the event is an enter press
            fn(...args);
        }
    };
}
