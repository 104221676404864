// https://plainjs.com/javascript/manipulation/wrap-an-html-structure-around-an-element-28/
const tableWrapperInit = () => {
    // element that will be wrapped
    const tables = document.querySelectorAll('table');

    Array.prototype.forEach.call(tables, table => {
        // create wrapper container
        const tableWrapper = document.createElement('div');
        tableWrapper.setAttribute('class', 'table-wrapper');

        table.parentNode.insertBefore(tableWrapper, table);
        tableWrapper.appendChild(table);
    });
};

tableWrapperInit();
