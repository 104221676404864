import Bouncer from './lib/bouncer.polyfills';

// ------------------------------------------------------------------------------------------
// For FED form validation we have decided to use Bouncer
// https://github.com/cferdinandi/bouncer
//
// Here are some use cases:
// 1. Custom Form - where the FED have 100% control over markup
//
// 2. Semi-custom form - where FEDs can control most of the markup, each form is wrapped in its own form tag
//                       we may have limited ablity to add class / attribute directly to the input
//                       (e.g. Kentico MVC projects)
//
// 3. Kentico Webform - where the whole page is inside a form tag, even tho there may be multiple different forms
//                      on the same page. Again limited ablity to add class / attribute directly to the input.
//                      (e.g. Crown Poker Contact Us form)
//
// 4. Kentico Form Build - Form built with the Kentico form builder by the client, no control over markup.
//                         (we will NOT SUPPORT this case)
//
// Note: This lib is written in vanilla JS, so lets keep it that way as we are phasing out the use of jQuery
// ------------------------------------------------------------------------------------------

// Define some const here
const customFormSelector = 'form.fed-custom-form-validation';
const customFieldWrapper = '.js-fed-validation';

// In a webform project, only the submit button wrapped in this class wrapper will trigger the validation
const webFormSubmitWrapper = '.fed-webform-validation-trigger';
const webFormSelector = '.fed-webform-validation';

// Function to push the fed validation attribute set on a form element's wrapper down to the for element
const pushAttributesIntoElement = fedWrappers => {
    if (!fedWrappers || !fedWrappers.length) { return; }

    // Loop through the wrappers
    fedWrappers.forEach(wrapper => {
        // Go through the wrapper's attributes
        for (let i = 0; i < wrapper.attributes.length; i += 1) {
            const attr = wrapper.attributes[i];

            // Check if this is a fed validation data attribute
            if (/^data-fed-validation-/.test(attr.name)) {
                // strip out prefix to get the attr name we want to push down to the child form element
                const finalAttr = attr.name.replace('data-fed-validation-', '');
                const finalValue = attr.value;

                // Push the attribute / value pair down to all child form elements
                const childElements = wrapper.querySelectorAll('input, select, textarea');
                childElements.forEach(elem => {
                    elem.setAttribute(finalAttr, finalValue);
                });
            }
        }
    });
};

// Custom validation setting / logic for bouncer
const customValidation = {
    customValidations: {
        valueMismatch: field => {
            // Look for a selector for a field to compare
            // If there isn't one, return false (no error)
            const selector = field.getAttribute('data-bouncer-match');
            if (!selector) return false;

            // Get the field to compare
            const otherField = field.form.querySelector(selector);
            if (!otherField) return false;

            // Compare the two field values
            // We use a negative comparison here because if they do match, the field validates
            // We want to return true for failures, which can be confusing
            return otherField.value !== field.value;
        }
    },
    messages: {
        valueMismatch: field => {
            const customMessage = field.getAttribute('data-bouncer-mismatch-message');
            return customMessage ? customMessage : 'Please make sure the fields match.';
        }
    }
};

// init the validation logic for custom form
const initCustomFormValidation = () => {
    const formElement = document.querySelectorAll(customFormSelector);
    if (!formElement || !formElement.length) { return; }

    // First we push the validation setting from the control's wrapper into the holding inputs
    const fedWrappers = document.querySelectorAll(`${customFormSelector} ${customFieldWrapper}`);
    pushAttributesIntoElement(fedWrappers);

    // Then we start the validator
    Bouncer(customFormSelector, customValidation);

    // Listen for errors on selects, to apply styling to parent.
    document.addEventListener('bouncerShowError', event => {
        // Add a class to the selects parent to colour the dropdown arrow red.
        const field = event.target;
        field.parentNode.classList.add('has-error');
    }, false);

    document.addEventListener('bouncerRemoveError', event => {
        // Remove the class from the selects parent that colours the dropdown arrow.
        event.target.parentNode.classList.remove('has-error');
    }, false);
};


// init the validation logic for kentico web form
const initWebFormValidation = () => {
    const formElement = document.querySelectorAll(webFormSelector);
    if (!formElement || !formElement.length) { return; }

    // First we push the validation setting from the control's wrapper into the holding inputs
    const fedWrappers = document.querySelectorAll(`${webFormSelector} ${customFieldWrapper}`);
    pushAttributesIntoElement(fedWrappers);

    // Then hook up the validation to submit button event and on blur event of the form elements.
    const bouncer = new Bouncer(webFormSelector, customValidation);
    formElement.forEach(formWrapper => {
        // Special treatment for Webform is we need to remove all the "required" attributes
        // as it'd stop one form from submitting if there are required fields on another form
        // (its the browser's behaviour)
        const fields = formWrapper.querySelectorAll(`${customFieldWrapper} input, ${customFieldWrapper} select, ${customFieldWrapper} textarea`);

        // Hook up the validaiton to on blur of form elements
        if (fields && fields.length) {
            fields.forEach(field => {
                // Remove webform project required attributres
                field.removeAttribute('required');

                // Handle on blur to trigger validate
                field.addEventListener('blur', event => {
                    // Here we actually should be pushing in any of the "required" attribute
                    const wrapper = event.target.closest(customFieldWrapper);
                    if (wrapper.hasAttribute('data-fed-validation-required')) {
                        event.target.setAttribute('required', 'required');
                    }

                    // Trigger the validation
                    bouncer.validate(event.target);

                    // Remove the "required" attributes to prevent it from killing other form group's sumbit function
                    event.target.removeAttribute('required');
                });
            });
        }

        // The submit buttons in the webform project must have the specific trigger class
        // in order to trigger the fed validation
        const submits = formWrapper.querySelectorAll(`${webFormSubmitWrapper} input[type="submit"], ${webFormSubmitWrapper} button`);
        if (submits && submits.length) {
            submits.forEach(btn => {
                btn.onclick = e => {
                    // Here we actually should be pushing in any of the "required" attributes
                    if (fields && fields.length) {
                        fields.forEach(field => {
                            const wrapper = field.closest(customFieldWrapper);
                            if (wrapper.hasAttribute('data-fed-validation-required')) {
                                field.setAttribute('required', 'required');
                            }
                        });
                    }

                    // Trigger the validation
                    const checkList = bouncer.validateAll(formWrapper);

                    // We have invalid fields, so prevent the default form submit action
                    if (checkList && checkList.length) {
                        e.preventDefault();
                    }

                    // Remove the "required" attributes to prevent it from killing other form group's sumbit function
                    if (fields && fields.length) {
                        fields.forEach(field => {
                            field.removeAttribute('required');
                        });
                    }
                };
            });
        }
    });
};

// Kick off the form validation code on dom ready
document.addEventListener('DOMContentLoaded', () => {
    initCustomFormValidation();
    initWebFormValidation();
});
