import Slideout from 'slideout';

const slideout = new Slideout({
    panel: document.getElementById('panel'),
    menu: document.getElementById('mobile-menu'),
    padding: 256,
    tolerance: 70,
    easing: 'ease-in-out',
    side: 'right'
});

// Toggle button
document.querySelector('.toggle-button').addEventListener('click', () => {
    slideout.toggle();
});

window.addEventListener('resize', () => {
    if (window.innerWidth >= 768 && document.querySelector('html').classList.contains('slideout-open')) {
        document.querySelector('.toggle-button').click();
    }
});
