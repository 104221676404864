import $ from 'jquery';

const setCurrentNavItems = el => {
    const $component = $(el);
    const $currentActiveLink = $component.find('.is-current');
    const url = window.location.pathname;
    const $newActiveLink = $component.find(`a[href="${url}"]`);
    const currentClass = 'is-current';

    // remove all active classes
    $currentActiveLink.removeClass(currentClass);
    // add class to anchor that matches url
    $newActiveLink.addClass(currentClass);
};

const styleguideInit = () => {
    const headerNavEl = '.sg-header';
    const footerNavEl = '.sg-footer';

    setCurrentNavItems(headerNavEl);
    setCurrentNavItems(footerNavEl);
};

$(() => {
    styleguideInit();
});
